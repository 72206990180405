import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import router from "@/router";
import ability from "@/libs/acl/ability";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { debounce } from "lodash";

export default function useList() {
  const { t } = useI18nUtils();
  const trans = {
    "messages.error.server_error": t("messages.error.server_error"),
  };
  const refListTable = ref(null);

  const toast = useToast();

  store.dispatch("tenant/getAll");

  const tenantOptions = computed(() => store.getters["tenant/getSelectedItems"]);
  const tenantFilter = ref(null);

  if (router.currentRoute.params.id_tenant)
    tenantFilter.value = parseInt(router.currentRoute.id_tenant);

  const tableColumns = [
    { label: "person_list.luna_id", key: "id_luna", sortable: true },
    { label: "person_list.list", key: "list_data", sortable: true },
    { label: "person_list.people", key: "count", sortable: true },
    { label: "person_list.review_list", key: "check_in", sortable: false },
    { label: "person_list.expected_similarity", key: "score_similarity", sortable: true },
    { label: "person_list.alarm_level", key: "description", sortable: true },
  ];
  if (
    ability.can("update", "PersonList") ||
    ability.can("delete", "PersonList")
  ) {
    tableColumns.splice(0, 0, { label: "actions.name", key: "actions" });
  }

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    store
      .dispatch("person_list/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: trans["messages.error.server_error"],
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch([tenantFilter, currentPage, perPage], () => {
    refetchData();
  });

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,

    tenantOptions,
    tenantFilter,

    refetchData,
  };
}
